<template>
  <SmallModal class="tw-z-20" @close="$emit('close-remove-modal')">
    <template v-slot:content>
      <div class="header">
        <h3 class="tw-text-xl tw-font-CircularStd-medium tw-font-medium">
          Remove member?
        </h3>
        <p class="tw-text-black-light tw-mt-6">
          This will remove this user from your deal room.
        </p>
      </div>
      <button
        class="tw-w-full tw-bg-error tw-text-white tw-rounded tw-py-3 tw-my-8"
        @click="removeMember"
      >
        <span v-if="!loading">Yes, Remove</span>
        <img
          v-show="loading"
          class="tw-m-auto"
          src="@/assets/img/spinner.svg"
          alt="animated spinner"
        />
      </button>
      <p class="tw-text-center">
        <span
          class="tw-text-gray tw-cursor-pointer"
          @click="$emit('close-remove-modal')"
        >
          No, never mind
        </span>
      </p>
    </template>
  </SmallModal>
</template>

<script setup>
  import SmallModal from "@/components/general/SmallModal";
  import { useStore } from "vuex";
  import { ref } from "vue";

  const store = useStore();
  const emit = defineEmits(["close-remove-modal", "update-members"]);
  const props = defineProps({
    member: {
      type: String,
      required: true,
    },
  });

  const loading = ref(false);

  const removeMember = async () => {
    const id = props.member;
    loading.value = true;
    await store.dispatch("organisationModule/removeMember", id);
    loading.value = false;
    emit("update-members");
  };
</script>
