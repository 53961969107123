<template>
  <SmallModal class="tw-z-20" @close="$emit('close-accept-modal')">
    <template v-slot:content>
      <div class="header">
        <h3 class="tw-text-xl tw-font-CircularStd-medium tw-font-medium">
          Accept user?
        </h3>
        <p class="tw-text-black-light tw-mt-6">
          This will add this user to your deal room.
        </p>
      </div>
      <button
        class="tw-w-full tw-bg-primary tw-text-white tw-rounded tw-py-3 tw-my-8"
        @click="acceptMember"
      >
        <span v-if="!loading">Yes, Accept</span>
        <img
          v-show="loading"
          class="tw-m-auto"
          src="@/assets/img/spinner.svg"
          alt="animated spinner"
        />
      </button>
      <p class="tw-text-center ">
        <span
          class="tw-text-gray tw-cursor-pointer"
          @click="$emit('close-accept-modal')"
        >
          No, never mind
        </span>
      </p>
    </template>
  </SmallModal>
</template>

<script>
  import SmallModal from "@/components/general/SmallModal";
  import { mapActions } from "vuex";
  export default {
    name: "AcceptModal",
    data() {
      return {
        loading: false,
      };
    },
    components: {
      SmallModal,
    },
    emits: ["close-accept-modal", "update-members"],
    props: {
      member: {
        type: String,
        required: true,
      },
    },
    methods: {
      ...mapActions("organisationModule", ["pendingInvite"]),

      async acceptMember() {
        this.loading = true;
        const payload = { id: this.member, action: "accept" };
        const response = await this.pendingInvite(payload);
        if (response) {
          this.$emit("update-members");
        }
        this.loading = false;
      },
    },
  };
</script>

<style lang="scss" scoped></style>
