<template>
  <div id="members-btn">
    <button
      class="tw-rounded-full tw-border tw-border-dashed tw-border-secondary tw-w-8 tw-h-8 md:tw-w-10 md:tw-h-10 tw-flex tw-items-center tw-justify-center hover:tw-bg-inherit"
      :class="[btnLoading ? 'tw-bg-primary' : null]"
      v-if="buttonType === 'round'"
      @click="generateLink"
    >
      <img
        v-if="btnLoading"
        class="tw-m-auto"
        src="@/assets/img/spinner.svg"
        alt="animated spinner"
      />
      <img v-else src="@/assets/img/add-circle-blue.svg" />
    </button>
    <button
      :disabled="disabled"
      v-else-if="buttonType === 'addMember'"
      @click="showMemberForm = true"
      class="tw-text-white tw-rounded tw-px-6 tw-w-max tw-h-12"
      :class="[disabled ? 'tw-bg-[#C2D3FF]' : 'tw-bg-primary']"
    >
      {{ title }}
    </button>
    <button
      :disabled="disabled"
      v-else-if="buttonType === 'addMultipleMembers'"
      @click="showMultipleMembersForm = true"
      class="tw-text-white tw-rounded tw-px-6 tw-w-max tw-h-12"
      :class="[disabled ? 'tw-bg-[#C2D3FF]' : 'tw-bg-primary']"
    >
      {{ title }}
    </button>
    <div
      v-else
      class="dropdown tw-relative tw-w-44 tw-flex tw-flex-col sm:tw-items-center"
    >
      <button
        @click="generateLink"
        :disabled="btnLoading"
        class="tw-text-white tw-bg-primary tw-rounded tw-px-6 tw-w-max tw-h-12"
      >
        <img
          v-if="btnLoading"
          class="tw-m-auto"
          src="@/assets/img/spinner.svg"
          alt="animated spinner"
        />
        <span v-else>{{ title }}</span>
      </button>
    </div>
    <Teleport to="#teleport-modal">
      <GenerateLinkModal
        v-if="showGenerateLinkModal"
        class="tw-z-20"
        @close="closeModal"
      >
        <template v-slot:content>
          <div class="header">
            <h3 class="tw-text-xl tw-font-CircularStd-medium tw-font-medium">
              Add member
            </h3>
          </div>
          <ul class="tw-flex tw-text-gray tw-border-b tw-mt-4 tw-mb-6">
            <li
              class="tw-mr-4 tw-cursor-pointer"
              :class="{
                'tw-text-gray-black tw-border-b-2 tw-border-primary':
                  activeTab === 'Invite link',
              }"
              @click="switchTab('Invite link')"
            >
              Invite link
            </li>
            <li
              class="tw-cursor-pointer"
              :class="{
                'tw-text-gray-black tw-border-b-2 tw-border-primary':
                  activeTab === 'Waitlist link',
              }"
              @click="switchTab('Waitlist link')"
            >
              Waitlist link
            </li>
          </ul>
          <InviteLinkForm
            v-if="activeTab === 'Invite link'"
            :link="link"
            :linkValidTime="linkValidTime"
            :btnLoading="btnLoading"
            :activeTab="activeTab"
            :loading="btnLoading"
            @deactivate="deactivateLink"
            @genLink="generateInviteLink"
          />
          <InviteLinkForm
            v-if="activeTab === 'Waitlist link'"
            :link="waitlist"
            :linkValidTime="listValidTime"
            :btnLoading="btnLoading"
            :activeTab="activeTab"
            :loading="btnLoading"
            @deactivate="deactivateLink"
            @genLink="generateInviteLink"
          />
        </template>
      </GenerateLinkModal>
      <AddMember
        v-if="showMemberForm"
        @close-modal="showMemberForm = false"
        @success="toggleSuccessModal"
      />
      <AddMultipleMembers
        v-if="showMultipleMembersForm"
        @success="toggleSuccessModal"
        @close-modal="showMultipleMembersForm = false"
      />
      <AddBankDetails
        v-if="showAddBankDetailsForm"
        @close-modal="showAddBankDetailsForm = false"
      />
      <SuccessModal
        v-if="showSuccessModal"
        @close="closeSuccessModal"
        :show="showSuccessModal"
        v-bind="{
          title: 'Success',
          body:
            'You have added this user to your dealroom.They have been notified via the provided email.',
        }"
      />
    </Teleport>
  </div>
</template>

<script setup>
  import GenerateLinkModal from "@/components/general/MediumModal";
  import InviteLinkForm from "./InviteLinkForm";
  import AddMember from "./AddMember.vue";
  import AddBankDetails from "./AddBankDetails.vue";
  import SuccessModal from "@/components/general/SuccessModal.vue";
  import AddMultipleMembers from "@/components/members/AddMultipleMembers.vue";
  import responseHandler from "@/utils/responseHandler.js";
  import api from "@/utils/api.js";
  import { getItem } from "@/utils/storage";
  import { getTimeLeft } from "@/utils/helpers.js";
  import { useStore } from "vuex";
  import { onBeforeUnmount, ref } from "vue";

  const store = useStore();

  const props = defineProps({
    title: {
      type: String,
      default: () => "",
      required: true,
    },
    buttonType: {
      type: String,
      required: false,
      default: () => "",
    },
    disabled: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  });

  const triggeredBy = ref(0);
  const showGenerateLinkModal = ref(false);
  const activeTab = ref("Invite link");
  const link = ref("");
  const linkId = ref("");
  const linkValidTime = ref("");
  const btnLoading = ref(false);
  const waitlist = ref("");
  const waitlistId = ref("");
  const listValidTime = ref("");
  const linkInterval = ref(null);
  const waitlistInterval = ref(null);
  const showMemberForm = ref(false);
  const showAddBankDetailsForm = ref(false);
  const showSuccessModal = ref(false);
  const showMultipleMembersForm = ref(false);

  const generateLink = () => {
    triggeredBy.value = 1;
    generateInviteLink();
  };

  const viewLink = () => {
    triggeredBy.value = 2;
    viewInviteLink();
  };
  const toggleSuccessModal = () => {
    showSuccessModal.value = true;
    showMultipleMembersForm.value = true;
    showMemberForm.value = false;
  };

  const closeSuccessModal = async () => {
    showSuccessModal.value = false;
    if (buttonType.value === "addMultipleMembers") {
    } else {
      showAddBankDetailsForm.value = true;
    }
  };

  const calculateTime = (link) => {
    const listCreatedAt = new Date(link?.createdAt).getTime();
    const expiryDate = new Date(listCreatedAt + link?.valid_period);
    const calculatedTime = getTimeLeft(expiryDate);
    return calculatedTime;
  };

  const viewInviteLink = async () => {
    btnLoading.value = true;
    try {
      let response = await api.viewInviteLink();
      const { data } = response.data;
      data.map((url) => {
        switch (url.waitlist) {
          case true:
            waitlist.value = url?.url;
            waitlistId.value = url?._id;
            waitlistInterval.value = setInterval(() => {
              listValidTime.value = calculateTime(url);
            }, 1000);
            break;
          case false:
            link = url?.url;
            linkId = url?._id;
            linkInterval = setInterval(() => {
              linkValidTime = calculateTime(url);
            }, 1000);
            break;
          default:
            break;
        }
      });
      setTimeout(() => {
        showGenerateLinkModal.value = true;
      }, 1000);
      activeTab.value = "Invite link";
      btnLoading.value = false;
    } catch (error) {
      responseHandler.handleError(error);
      btnLoading.value = false;
    }
  };

  const clearTimer = () => {
    clearInterval(linkInterval.value);
    clearInterval(waitlistInterval.value);
  };

  const generateInviteLink = async () => {
    clearTimer();
    btnLoading.value = true;
    let originUrl = null;
    const orgDetails = getItem("org_details")._id;
    const orgId = orgDetails._id;
    const slug = orgDetails.slug;
    switch (window.location.host) {
      case "dealroom.getequity.io":
        originUrl = `https://${slug}.getequity.co`;
        break;
      case "founders.getequity.io":
        originUrl = "https://app.getequity.io";
        break;
      default:
        originUrl = "https://staging--getequityinvestors.netlify.app";
        break;
    }
    const url = `${originUrl}/dashboard/deal-room/${orgId}`;
    const linkPayload = { waitlist: false, mail_base_url: url };
    const waitlistPayload = { waitlist: true, mail_base_url: url };
    let linkResponse = api.generateInviteLink(linkPayload);
    let waitlistResponse = api.generateInviteLink(waitlistPayload);
    try {
      const response = await Promise.all([linkResponse, waitlistResponse]);
      const linkRes = response[0].data.data;
      const waitlistRes = response[1].data.data;
      link.value = linkRes?.link;
      waitlist.value = waitlistRes?.link;
      linkId.value = linkRes?._id;
      waitlistId.value = waitlistRes?._id;
      linkInterval.value = setInterval(() => {
        linkValidTime.value = calculateTime(linkRes);
      }, 1000);
      waitlistInterval.value = setInterval(() => {
        listValidTime.value = calculateTime(waitlistRes);
      }, 1000);
      showGenerateLinkModal.value = true;
      activeTab.value = "Invite link";
      btnLoading.value = false;
    } catch (error) {
      responseHandler.handleError(error);
      btnLoading.value = false;
    }
  };

  const deactivateLink = async () => {
    btnLoading.value = true;
    let id = null;
    switch (activeTab.value) {
      case "Invite link":
        id = linkId.value;
        break;
      case "Waitlist link":
        id = waitlistId.value;
        break;
    }
    try {
      const response = await api.deactivateInviteLink(id);
      const { message } = response.data;
      store.dispatch("showToast", {
        description: message,
        display: true,
        type: "success",
      });
      switch (activeTab.value) {
        case "Invite link":
          linkId.value = "";
          link.value = "";
          linkValidTime.value = "";
          break;
        case "Waitlist link":
          waitlistId.value = "";
          waitlist.value = "";
          listValidTime.value = "";
          break;
      }
      btnLoading.value = false;
      clearTimer();
    } catch (error) {
      responseHandler.handleError(error);
      btnLoading.value = false;
    }
  };

  const switchTab = (tab) => {
    activeTab.value = tab;
  };

  const closeModal = () => {
    clearTimer();
    showGenerateLinkModal.value = !showGenerateLinkModal.value;
    linkId.value = "";
    link.value = "";
    linkValidTime.value = "";
    waitlistId.value = "";
    waitlist.value = "";
    listValidTime.value = "";
  };

  onBeforeUnmount(() => {
    clearTimer();
  });
</script>
