<template>
  <Modal :show="true" @close-modal="$emit('close-modal')">
    <div class="tw-w-full md:tw-w-[480px] lg:tw-w-[800px]">
      <form
        @submit.prevent="checkFormValidation"
        class="tw-w-full tw-mx-auto md:tw-max-w-[680px]"
      >
        <h1
          class="tw-font-bold tw-text-[22px] tw-text-secondary tw-mb-1 tw-text-left"
        >
          Create new member
        </h1>
        <p class="tw-text-black-light tw-text-left">
          Provide the user’s details as it appears on their ID.
        </p>
        <div
          class="md:tw-flex tw-gap-x-3 tw-gap-y-3 lg:tw-gap-x-7 tw-flex-wrap tw-w-full tw-mt-10 tw-items-start"
        >
          <InputField
            class="md:tw-w-[233px] lg:tw-w-full lg:tw-max-w-[325px]"
            label="First name"
            name="fname"
            @input-changed="storeValue"
            @is-valid="handleValidation"
            :validate="validate"
          />
          <InputField
            class="md:tw-w-[233px] lg:tw-w-full lg:tw-max-w-[325px]"
            label="Last name"
            name="lname"
            @input-changed="storeValue"
            @is-valid="handleValidation"
            :validate="validate"
          />
          <InputField
            class="md:tw-w-[233px] lg:tw-w-full lg:tw-max-w-[325px]"
            label="Email"
            name="email"
            type="email"
            @input-changed="storeValue"
            @is-valid="handleValidation"
            :validate="validate"
          />
          <PhoneInput
            class="md:tw-w-[233px] lg:tw-w-full lg:tw-max-w-[325px]"
            label="Phone"
            name="phone"
            :required="true"
            @phone-input="storeValue"
            @is-valid="handleValidation"
            :validate="validate"
          />
          <div class="md:tw-w-[233px] lg:tw-w-full lg:tw-max-w-[325px]">
            <InputField
              label="username (optional)"
              name="username"
              :required="false"
              @input-changed="storeValue"
              @is-valid="handleValidation"
              :validate="validate"
            />
            <p v-if="usernameLookupResponse" class="tw-mt-1">
              {{ usernameLookupResponse }}
            </p>
            <p v-if="checkingUsername" class="tw-mt-1">
              Please wait...
            </p>
          </div>
          <OnboardingPasswordInput
            class="md:tw-w-[233px] lg:tw-w-full lg:tw-max-w-[325px]"
            label="Password"
            name="password"
            border="tw-border-[#DDE1E9]"
            :password="payload.password"
            :error="passwordError || {}"
            inputName="password"
            :disabled="loading"
            @set="storeValue({ value: $event, name: 'password' })"
          />
        </div>
        <div class="tw-flex tw-flex-wrap tw-gap-x-3 tw-mt-8">
          <button
            @click="$emit('close-modal')"
            class="tw-rounded tw-bg-blue-bg200 tw-max-w-[153px] tw-w-full tw-font-CircularStd-medium tw-font-medium tw-text-secondary tw-text-base"
          >
            Cancel
          </button>
          <div class="tw-w-full tw-max-w-[153px]" v-if="!checkingUsername">
            <BtnComponent title="Continue" :loading="loading" />
          </div>
        </div>
      </form>
    </div>
  </Modal>
</template>

<script setup>
  import Modal from "@/components/general/Modal";
  import InputField from "../general/InputField.vue";
  import PhoneInput from "../general/PhoneInput.vue";
  import BtnComponent from "../general/BtnComponent.vue";
  import OnboardingPasswordInput from "../onboarding/OnboardingPasswordInput.vue";
  import { useValidation } from "@/composables/formValidation";
  import { computed, reactive, ref, watch } from "vue";
  import { useStore } from "vuex";

  const emit = defineEmits(["close-modal", "success"]);
  const store = useStore();

  const loading = computed(() => store.getters["loading"]);

  const orgDetails = computed(
    () => store.getters["organisationModule/organisationDetails"]
  );

  const {
    fields,
    checkFormValidation,
    handleValidation,
    validate,
    canSubmit,
  } = useValidation();

  const payload = reactive({
    email: "",
    password: "",
    fname: "",
    lname: "",
    username: "",
    phone: "",
    kyc: true,
  });
  const passwordError = ref({});
  const usernameLookupResponse = ref("");
  const usernameValid = ref(false);
  const checkingUsername = ref(false);

  const storeValue = ({ name, value }) => {
    if (name === "phone") {
      let number = `${value.code}${value.number}`;
      number = String(number)
        .split(" ")
        .join("");
      payload.phone = number;
    } else {
      payload[name] = value;
    }
  };
  const addMemberToDealRoom = async () => {
    if (!payload.username) {
      delete payload.username;
    }
    try {
      await store.dispatch("organisationModule/addMemberToDealRoom", {
        payload,
        id: orgDetails.value._id,
      });
      emit("success");
    } catch (error) {
      return error;
    }
  };
  const lookupUsername = async (username) => {
    usernameLookupResponse.value = null;
    checkingUsername.value = true;
    try {
      const response = await store.dispatch(
        "organisationModule/lookupUsername",
        username
      );
      usernameLookupResponse.value = response.data.message;
      usernameValid.value = true;
      checkingUsername.value = false;
    } catch (error) {
      usernameValid.value = false;
      checkingUsername.value = false;
      usernameLookupResponse.value = error.response.data.message;
    }
  };
  watch(validate, (value) => {
    if (value) {
      if (payload.password) {
        handleValidation(true);
      } else {
        passwordError.value = {
          $property: "password",
          $message: "Please enter a valid password",
        };
        handleValidation(false);
      }
    }
  });
  watch(
    () => payload.username,
    (username) => {
      setTimeout(() => {
        if (payload.username === username && username.length > 1) {
          lookupUsername(username);
        }
      }, 1500);
    }
  );
  watch(canSubmit, (value) => {
    if (
      (value && payload.username && usernameValid.value) ||
      (value && !payload.username)
    ) {
      addMemberToDealRoom();
    }
  });
  fields.value = 6;
</script>

<style lang="scss" scoped></style>
