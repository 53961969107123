<template>
  <div id="text-input" class="tw-relative">
    <p>
      <label v-if="showLabel" :for="label" class="tw-text-sm">
        {{ label }}
      </label>
    </p>
    <input
      :type="type"
      class="tw-w-full tw-text-sm tw-rounded tw-pl-4 tw-pr-4 tw-pt-4 tw-pb-4 focus:tw-border-primary"
      :class="[
        border,
        showError ? 'tw-border-error' : '',
        disabled ? 'tw-bg-gray-light tw-border-0 tw-cursor-not-allowed' : '',
        name === 'website' ? 'website' : '',
        name === 'facebook' ? 'facebook' : '',
        name === 'twitter' ? 'twitter' : '',
        name === 'linkedin' ? 'linkedin' : '',
      ]"
      v-model="textData"
      @input="setInput"
      @keyup.enter="validate"
      @blur="validate"
      :placeholder="placeHolder"
      :disabled="disabled"
      :id="id"
      :name="name"
      :required="required"
    />
    <div
      v-if="fieldType === 'url'"
      class="url tw-absolute tw-text-xs tw-bg-primary-light tw-rounded tw-py-4 tw-px-2"
    >
      <span v-if="name === 'website'">https://</span>
      <span v-if="name === 'facebook'">https://facebook.com/</span>
      <span v-if="name === 'twitter'">https://twitter.com/</span>
      <span v-if="name === 'linkedin'">https://linkedin.com/in/</span>
    </div>
    <p class="tw-absolute tw-top-2 tw-right-px tw-text-error tw-text-xs">
      {{ errorMsg }}
    </p>
  </div>
</template>

<script>
  import useValidate from "@vuelidate/core";
  import { required, email, url } from "@vuelidate/validators";

  export default {
    name: "TextInput",

    emits: ["set", "valid"],

    props: {
      placeHolder: { type: String, default: () => "", required: true },
      label: { type: String, default: () => "", required: true },
      border: {
        type: String,
        required: false,
        default: () => "",
      },
      id: { type: String, default: () => "" },
      name: { type: String, default: () => "" },
      text: { type: String, default: () => "" },
      fieldType: { type: String, default: () => "" },
      email: { type: String, default: () => "" },
      url: { type: String, default: () => "" },
      type: { type: String, default: () => "" },
      showLabel: { type: Boolean, default: () => false },
      disabled: { type: Boolean, default: () => false },
      required: { type: Boolean, default: () => true },
    },

    data() {
      return {
        v$: useValidate(),
        textData: "",
        errorMsg: "",
        showError: false,
      };
    },

    validations() {
      return {
        text: { required },
        email: { required, email },
        url: { required },
      };
    },

    mounted() {
      switch (this.fieldType) {
        case "text":
          this.textData = this.text;
          break;
        case "email":
          this.textData = this.email;
          break;
        case "url":
          // if (this.name === "twitter" || this.name === "facebook") return;
          this.textData = this.url;
          break;

        default:
          break;
      }
      this.textData === "" ? "" : this.validate();
    },

    methods: {
      setInput() {
        this.showError = false;
        this.errorMsg = "";
        this.$emit("set", this.textData);
      },

      validate() {
        this.v$.$reset();
        switch (this.fieldType) {
          case "text":
            this.v$.text.$touch();
            break;
          case "email":
            this.v$.email.$touch();
            break;
          case "url":
            if (
              this.name === "twitter" ||
              this.name === "facebook" ||
              this.name === "linkedin"
            )
              return;
            this.v$.url.$touch();
            break;

          default:
            break;
        }
        if (this.v$.$errors.length === 0) {
          this.$emit("valid", true);
          return (this.showError = false), (this.errorMsg = "");
        }
        this.$emit("valid", false);
        this.errorMsg = this.v$.$errors[0].$message;
        this.showError = true;
      },
    },

    watch: {
      text(newValue, oldValue) {
        deep: true;
        immediate: true;
        if (newValue !== oldValue) {
          this.textData = newValue;
          this.validate();
        }
      },

      email(newValue, oldValue) {
        deep: true;
        immediate: true;
        if (newValue !== oldValue) {
          this.textData = newValue;
          this.validate();
        }
      },

      url(newValue, oldValue) {
        deep: true;
        immediate: true;
        if (newValue !== oldValue) {
          this.textData = newValue;
          this.validate();
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  #text-input {
    .website {
      padding-left: 4rem;
    }
    .facebook {
      padding-left: 9.2rem;
    }
    .twitter {
      padding-left: 8.1rem;
    }
    .linkedin {
      padding-left: 9.6rem;
    }
    .url {
      bottom: 3px;
      left: 3px;
    }
  }
</style>
