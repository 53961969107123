export default {
    data() {
        return {
            showFilterOptions: false,
            timeout: null,
        }
    },
    methods: {
        startTimeout() {
            if (this.showFilterOptions) {
                this.timeout = setTimeout(() => {
                    this.showFilterOptions = false
                }, 3000)
            }
        },
        deleteTimeout() {
            clearTimeout(this.timeout)
            this.timeout = null
        },
    },
    beforeDestroy() {
        this.deleteTimeout()
    },
}