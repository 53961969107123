<template>
  <div id="tablenav">
    <div class="tw-border-b tw-border-gray-light">
      <ul class="tw-text-lg tw-text-gray tw-font-medium xl:tw-flex tw-hidden">
        <li
          v-for="(link, index) in menu"
          :key="'link' + index"
          class="tw-cursor-pointer tw-mr-14 tw-py-4 tw-font-CircularStd-medium tw-font-medium"
          :class="{
            'tw-text-gray-black tw-border-b-2 tw-border-primary':
              link.status === status,
          }"
          @click="click(link.status)"
        >
          {{ link.title }}
        </li>
      </ul>
      <ul
        class="mobile-table-nav tw-inline-block tw-text-lg tw-font-medium xl:tw-hidden"
      >
        <li
          class="tw-relative tw-flex tw-items-center tw-cursor-pointer tw-py-2"
        >
          <span v-if="status !== 'Inactive'" class="tw-px-3">
            {{ status ? status : "All Members" }}
          </span>
          <span v-if="status === 'Inactive'" class="tw-px-3">
            {{ status === "Inactive" ? "Deactivated" : "" }}
          </span>
          <svg
            width="8"
            height="4"
            viewBox="0 0 8 4"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.00011 2.58602L6.47512 0.111023L7.18212 0.818023L4.00011 4.00002L0.818115 0.818023L1.52512 0.111023L4.00011 2.58602Z"
              fill="#8791A7"
            />
          </svg>
        </li>
        <li class="menu tw-absolute tw-bg-white tw-rounded tw-hidden">
          <p
            v-for="(link, index) in menu"
            :key="'link' + index"
            class="link tw-text-lg tw-py-1 tw-px-3 tw-mx tw-my-4 tw-rounded tw-cursor-pointer"
            @click="click(link.status)"
          >
            {{ link.title }}
          </p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  export default {
    name: "TableNav",

    emits: ["menuClick"],

    props: {
      menu: { type: Array, default: () => [], required: true },
      status: { type: String, default: () => "", required: true },
    },

    methods: {
      click(status) {
        this.$emit("menuClick", { page: 1, status });
      },
    },
  };
</script>

<style lang="scss" scoped>
  #tablenav {
    .mobile-table-nav {
      .menu {
        z-index: 2;
        .link {
          transition: 0.3s ease all;

          &:hover {
            background-color: #375cb1;
            color: #ffffff;
          }
        }
      }
    }

    &:hover .menu {
      display: block;
    }
  }
</style>
