<template>
  <Modal class="tw-z-20" @close="$emit('close-activate-modal')">
    <template v-slot:content>
      <div class="header">
        <h3 class="tw-text-xl tw-font-CircularStd-medium tw-font-medium">
          Activate member?
        </h3>
        <p class="tw-text-black-light tw-mt-6 tw-text-justify">
          This will give this user access to participate in your deal room.
        </p>
      </div>
      <button
        class="tw-w-full tw-bg-primary tw-text-white tw-rounded tw-py-3 tw-my-8"
        @click="activateAMember"
      >
        <span v-if="!loading">Yes, Activate</span>
        <img
          v-show="loading"
          class="tw-m-auto"
          src="@/assets/img/spinner.svg"
          alt="animated spinner"
        />
      </button>
      <p class="tw-text-center ">
        <span
          class="tw-text-gray tw-cursor-pointer"
          @click="$emit('close-activate-modal')"
        >
          No, never mind
        </span>
      </p>
    </template>
  </Modal>
</template>

<script>
  import Modal from "@/components/general/SmallModal";
  import { mapActions } from "vuex";
  export default {
    name: "ActivateModal",
    data() {
      return {
        loading: false,
      };
    },
    components: {
      Modal,
    },
    props: {
      member: {
        type: String,
        required: true,
      },
    },
    emits: ["close-activate-modal", "update-members"],
    methods: {
      ...mapActions("organisationModule", ["activateMember"]),

      async activateAMember() {
        this.loading = true;
        const response = await this.activateMember(this.member);
        if (response) {
          this.$emit("update-members");
        }
        this.loading = false;
      },
    },
  };
</script>
