<template>
  <div id="members">
    <div v-if="!loading" class="container tw-px-4">
      <div
        class="tw-flex tw-flex-col sm:tw-flex-row tw-justify-between tw-mb-10 lg:tw-px-4 tw-items-center"
      >
        <div>
          <h3 class="tw-text-2xl tw-font-bold tw-pb-1">
            Members
          </h3>
        </div>
        <div class="tw-flex tw-gap-x-2">
          <DealRoomInvite
            :disabled="isDisabled"
            title="Add New Member"
            buttonType="addMember"
          />
          <DealRoomInvite
            v-if="plan.type.toLowerCase() !== 'free'"
            :disabled="isDisabled"
            title="Add Multiple Members"
            buttonType="addMultipleMembers"
          />
        </div>
      </div>
      <SubscriptionLimitStatus
        @show-plans="
          $router.push({
            name: 'SubscriptionPlans',
            query: { plans: true },
          })
        "
      />
      <div class="tw-bg-white tw-rounded-lg lg:tw-mx-4">
        <div class="tw-p-4 md:tw-px-0">
          <div
            class="tw-flex tw-justify-between tw-items-center tw-px-1 lg:tw-px-10 tw-border-b tw-border-gray-light"
          >
            <TableNav
              :menu="tableNavMenu"
              :status="status"
              @menuClick="setStatus"
            />
            <div class="tw-flex tw-items-center">
              <div class="tw-mr-4">
                <Search
                  v-if="status !== 'Waitlist'"
                  :showSearchField="showSearchField"
                  @search-click="showSearchField = !showSearchField"
                  @search-enter="searchMembers"
                  @search-input="getAllMembers"
                />
              </div>
            </div>
          </div>
          <div class="table-container">
            <MembersTable
              v-if="members && members.length !== 0 && !searchLoading"
              :tableHeaders="tableHeaders"
              :tableRows="tableRows"
              :columnNumber="tableHeaders.length - 2"
              :columnWidth="200"
              :totalPage="pages"
              :currentPage="page"
              :deals="deals"
              @gotoPage="gotoPage"
              @update-members="getAllMembers({ page, status })"
            />
            <EmptyState v-if="!members || members.length === 0">
              <template v-slot:message>
                <div class="tw-text-center tw-text-gray tw-my-3">
                  <p v-if="status === 'All members' || status === ''">
                    You currently do not have anyone in your dealroom
                  </p>
                  <p v-if="status === 'Waitlist'">
                    There are no pending members on this account.
                  </p>
                  <p v-if="status === 'Inactive'">
                    There are no deactivated members on this account.
                  </p>
                </div>
              </template>
            </EmptyState>
            <div v-if="searchLoading" class="tw-bg-white tw-h-52">
              <Loader v-if="searchLoading" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <Loader v-if="loading" />
  </div>
</template>

<script>
  import { mapActions, mapGetters } from "vuex";
  import EmptyState from "@/components/notification/EmptyState";
  import TableNav from "@/layout/navigation/TableNav";
  import Search from "@/components/general/Search";
  import MembersTable from "@/components/tables/MembersTable";
  import DealRoomInvite from "@/components/members/DealRoomInvite";
  import SubscriptionLimitStatus from "@/components/account/subscription/SubscriptionLimitStatus.vue";

  import responseHandler from "@/utils/responseHandler.js";
  import { formatISOTime, formatISODate, truncate } from "@/utils/helpers.js";
  import api from "@/services/org";
  import filter from "@/mixins/filter";
  import search from "@/mixins/search";
  export default {
    name: "Members",
    mixins: [filter, search],

    components: {
      MembersTable,
      TableNav,
      EmptyState,
      Search,
      SubscriptionLimitStatus,
      DealRoomInvite,
    },

    data() {
      return {
        loading: false,
        btnLoading: false,
        searchLoading: false,
        showGenerateLinkModal: false,
        search: "",
        tableHeaders: [
          "Name",
          "Email",
          "Date Joined",
          "Status",
          "Action",
          "id",
        ],
        tableRows: [],
        deals: [],
        filteredMembers: "",
        allMembers: 0,
        memberLimit: 0,
        status: "",
        allMembers: null,
        activeMembers: null,
        deactivatedMembers: null,
        // tableNavMenu: [
        //   { title: "All members", status: "" },
        //   { title: "Active", status: "Active" },
        //   // { title: "Waitlist", status: "Waitlist" },
        //   { title: "Deactivated", status: "Inactive" },
        // ],
        filterOptions: ["Active", "Inactive"],
      };
    },

    created() {
      this.getPageInfo();
      this.getAllMembers();
    },
    computed: {
      ...mapGetters("Deals", ["dealRequests"]),
      ...mapGetters("organisationModule", ["plan"]),
      tableNavMenu() {
        return [
          {
            title: `All members (${this.allMembers?.data.data.members.length})`,
            status: "",
          },
          {
            title: `Active (${this.activeMembers?.data.data.members.length})`,
            status: "Active",
          },
          // { title: "Waitlist", status: "Waitlist" },
          {
            title: `Deactivated (${this.deactivatedMembers?.data.data.members.length})`,
            status: "Inactive",
          },
        ];
      },
      members() {
        let members = [];
        switch (this.status) {
          case "":
            members = this.allMembers?.data.data.members;
            break;
          case "Active":
            members = this.activeMembers?.data.data.members;
            break;
          case "Inactive":
            members = this.deactivatedMembers?.data.data.members;
            break;
        }
        return members;
      },
      page() {
        let page = 1;
        switch (this.status) {
          case "":
            page = this.allMembers?.data.data.page;
            break;
          case "Active":
            page = this.activeMembers?.data.data.page;
            break;
          case "value":
            page = this.deactivatedMembers?.data.data.page;
            break;
        }
        return page;
      },
      pages() {
        let pages = 1;
        switch (this.status) {
          case "value":
            pages = this.allMembers?.data.data.pages;
            break;
          case "value":
            pages = this.activeMembers?.data.data.pages;
            break;
          case "value":
            pages = this.deactivatedMembers?.data.data.pages;
            break;
        }
        return pages;
      },
      isDisabled() {
        return (
          this.memberLimit === this.activeMembers?.data.data.members.length &&
          this.plan.type.toLowerCase() === "free"
        );
      },
    },

    methods: {
      ...mapActions(["showToast"]),
      ...mapActions("organisationModule", [
        "getOrganisationPlan",
        "getOrganisationProfile",
      ]),

      async getPageInfo() {
        const query = { page: this.page, status: this.status };
        let approvedDeals = api.getApprovedDeals();
        let allMembers = api.getAllMembers(query);
        let orgPlan = this.getOrganisationPlan();
        try {
          const response = await Promise.all([
            approvedDeals,
            allMembers,
            orgPlan,
          ]);
          const { tokens } = response[0].data.data;
          const { members_limit } = response[2].data.data;
          this.memberLimit = members_limit;
          tokens.map((token) => {
            if (token.deal_access === "Public") {
              this.deals.push(token);
            }
          });
        } catch (error) {
          responseHandler.handleError(error);
          this.loading = false;
        }
      },
      setStatus(query) {
        this.status = query.status;
      },
      async getAllMembers() {
        this.loading = true;
        try {
          const [
            allMembers,
            activeMembers,
            deactivatedMembers,
          ] = await Promise.all([
            api.getAllMembers({ page: 1 }),
            api.getAllMembers({ page: 1, status: "Active" }),
            api.getAllMembers({ page: 1, status: "InActive" }),
          ]);
          this.allMembers = allMembers;
          this.activeMembers = activeMembers;
          this.deactivatedMembers = deactivatedMembers;
          this.loading = false;
        } catch (error) {
          console.log({ error });
          responseHandler.handleError(error);
          this.loading = false;
        }
      },

      async searchMembers({ value, type }) {
        if (value === "") return;
        this.searchLoading = true;
        try {
          const response = await api.searchMembers(value);
          const { data } = response.data;
          this.members = data.filter((member) => {
            if (this.status === "") return member;
            if (this.status === member.status) return member;
          });
          this.searchLoading = false;
        } catch (error) {
          this.searchLoading = false;
          responseHandler.handleError(error);
        }
      },

      resetTable({ value, type }) {
        const query = { page: this.page, status: this.status };
        if (value === "") {
          this.status = "";
        }
      },

      async gotoPage(page) {
        this.loading = true;
        const query = { page: page, status: this.status };
        try {
          await api.getAllMembers(query);
          this.loading = false;
        } catch (error) {
          responseHandler.handleError(error);
          this.loading = false;
        }
      },

      updateTableRow() {
        this.tableRows = this.members?.map((member) => {
          let name = "";
          let status = member.status;
          switch (status) {
            case "Inactive":
              status = "Deactivated";
              break;

            case "Pending_Approval":
              status = "Pending";
              break;
            default:
              break;
          }
          if (status == "Pending") {
            name = member.name;
          } else {
            name = `${member.user ? member.user.fname : member.fname} ${
              member.user ? member.user.lname : member.lname
            }`;
          }
          return {
            name: `<span class="tw-font-bold">${name}</span>`,
            email: truncate(
              member.user?.email ? member.user?.email : member.email,
              25
            ),
            joined: `${formatISODate(
              member.createdAt || member.date_joined
            )}  ${formatISOTime(member.createdAt || member.date_joined)}`,
            id: `data-id/${member.member_id ? member.member_id : member._id}/${
              member.user ? member.user._id : member._id
            }`,
            status: status,
          };
        });
      },
    },
    beforeUnmount() {
      clearInterval(this.linkInterval);
      clearInterval(this.waitlistInterval);
    },
    watch: {
      members(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.getOrganisationProfile();
          this.updateTableRow();
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  #members {
    width: 100%;
    overflow: hidden;

    .container {
      @include fadeIn;

      @media screen and (min-width: 1024px) {
        max-width: 1280px;
        margin-left: auto;
        margin-right: auto;
      }
    }

    .table-container {
      overflow: auto;
    }
  }
</style>
