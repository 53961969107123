<template>
  <div id="invite-link-form">
    <div>
      <div v-if="link">
        <div class="tw-text-center tw-mb-8">
          <p class="tw-text-gray">This link will expire in</p>
          <h3 class="tw-text-xl tw-font-bold">
            <span class="tw-w-52">{{ linkValidTime }}</span>
            <p
              class="tw-text-gray tw-text-xs"
              v-if="linkValidTime !== 'EXPIRED'"
            >
              <span>day(s)</span><span class="tw-mx-2">hr(s)</span>
              <span class="tw-mx-2">min(s)</span>
              <span>sec(s)</span>
            </p>
          </h3>
        </div>
        <label for="invite link"></label>
        <input
          class="tw-w-full tw-text-sm tw-break-words tw-border tw-rounded tw-px-5 tw-py-3 tw-mb-7"
          type="text"
          readonly
          :value="link"
          @focus="$event.target.select()"
          ref="link"
        />
        <div
          v-if="linkValidTime !== 'EXPIRED'"
          class="tw-flex tw-flex-col tw-justify-between sm:tw-flex-row"
        >
          <button
            class="tw-rounded tw-text-white tw-bg-primary tw-px-16 tw-py-3 tw-mb-6 sm:tw-mb-0"
            @click="copyInviteLink"
          >
            <span>Copy invite link</span>
          </button>
          <button
            class="tw-border tw-rounded tw-text-error tw-px-8 tw-py-3"
            @click="$emit('deactivate')"
          >
            <span v-show="!btnLoading">Deactivate link</span>
            <img
              v-show="btnLoading"
              class="tw-m-auto"
              src="@/assets/img/spinner.svg"
              alt="animated spinner"
            />
          </button>
        </div>
        <div class="tw-flex tw-justify-center">
          <button
            v-if="linkValidTime === 'EXPIRED'"
            class="tw-w-full tw-text-white tw-bg-primary tw-cursor-pointer tw-rounded tw-px-16 tw-py-3"
            :disabled="loading"
            @click="$emit('genLink')"
          >
            <img
              v-if="loading"
              class="tw-m-auto"
              src="@/assets/img/spinner.svg"
              alt="animated spinner"
            />
            <span v-else>Generate link</span>
          </button>
        </div>
      </div>
      <EmptyState v-if="!link">
        <template v-slot:message>
          <p class="message tw-text-center tw-text-gray tw-my-3">
            This link has been removed, please generate a new link.
          </p>
        </template>
      </EmptyState>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import EmptyState from "@/components/notification/EmptyState.vue";

export default {
  name: "InviteLinkForm",

  emits: ["deactivate", "genLink"],

  components: { EmptyState },

  props: {
    link: { type: String, default: () => "", required: true },
    linkValidTime: { type: String, default: () => "", required: true },
    activeTab: { type: String, default: () => "", required: true },
    btnLoading: { type: Boolean, default: () => false, required: true },
    loading: { type: Boolean, default: () => false },
  },

  methods: {
    ...mapActions(["showToast"]),

    copyInviteLink() {
      this.$refs.link.focus();

      // TODO: Look for an alternative to execCommand
      document.execCommand("copy");
      this.showToast({
        description: "Link copied",
        display: true,
        type: "success",
      });
    },
  },

  // watch: {
  //   linkValidTime(newValue, oldValue) {
  //     deep: true;
  //     immediate: true;
  //     if (newValue !== oldValue) {
  //       console.log(this.linkValidTime);
  //     }
  //   },
  // },
};
</script>

<style lang="scss" scoped></style>
