<template>
  <Modal :show="true" @close-modal="$emit('close-modal')">
    <div class="tw-w-full md:tw-w-[480px] lg:tw-w-[800px]">
      <form
        @submit.prevent="addBank"
        class="tw-w-full tw-mx-auto md:tw-max-w-[680px]"
      >
        <div class="tw-flex tw-justify-between">
          <div>
            <h1
              class="tw-font-bold tw-text-[22px] tw-text-secondary tw-mb-1 tw-text-left"
            >
              Member’s Bank Details
            </h1>
            <p class="tw-text-black-light tw-text-left">
              Provide the user’s bank details
            </p>
          </div>
          <!-- <span class="tw-text-primary" @click="emit('close-modal')"
            >Skip bank details</span
          > -->
        </div>
        <div
          class="md:tw-flex tw-gap-x-3 tw-gap-y-3 lg:tw-gap-x-7 tw-flex-wrap tw-w-full tw-mt-10 tw-items-start"
        >
          <TextInput
            class="md:tw-w-[233px] lg:tw-w-full lg:tw-max-w-[325px] tw-mb-8"
            placeHolder="Account Name"
            :text="payload.account_name"
            type="text"
            name="accountName"
            id="accountName"
            label="Account Name"
            @set="setAccountName"
            @valid="accountNameValid"
          />
          <TextInput
            class="md:tw-w-[233px] lg:tw-w-full lg:tw-max-w-[325px] tw-mb-8"
            placeHolder="Account Number"
            :text="payload.account_number"
            type="text"
            name="accountNumber"
            id="accountNumber"
            label="Account Number"
            @set="setAccountNumber"
            @valid="accountNumberValid"
          />
          <SelectInput
            class="md:tw-w-[233px] lg:tw-w-full lg:tw-max-w-[325px] tw-mb-8"
            label="Currency"
            defaltOption="Select currency"
            name="currency"
            id="currency"
            :list="currencyList"
            @set="setCurrency"
          />
          <TextInput
            class="md:tw-w-[233px] lg:tw-w-full lg:tw-max-w-[325px] tw-mb-8"
            placeHolder="Bank name"
            :text="payload.name"
            type="text"
            name="bankName"
            id="bankName"
            label="Bank name"
            @set="setBankName"
            @valid="bankNameValid"
          />
          <TextInput
            class="md:tw-w-[233px] lg:tw-w-full lg:tw-max-w-[325px] tw-mb-8"
            placeHolder="Sort code"
            :text="payload.sort_code"
            type="text"
            name="sortCode"
            id="sortCode"
            label="Sort code"
            @set="setSortCode"
            @valid="sortCodeValid"
          />
          <TextInput
            v-if="payload.currency !== 'NGN' && payload.currency !== ''"
            class="md:tw-w-[233px] lg:tw-w-full lg:tw-max-w-[325px] tw-mb-8"
            placeHolder="Swift code"
            :text="payload.swift_code"
            type="text"
            name="swiftCode"
            id="swiftCode"
            label="Swift code"
            @set="setSwiftCode"
            @valid="swiftCodeValid"
          />
          <TextAreaInput
            class="tw-w-full"
            placeHolder="Bank address"
            label="Bank address"
            name="bankAddress"
            id="bankAddress"
            :text="payload.bank_address"
            :maxlength="200"
            :rows="3"
            @set="setBankAddress"
            @valid="bankAddressValid"
          />
        </div>
        <div class="tw-flex tw-flex-wrap tw-gap-x-3 tw-mt-8">
          <button
            @click="$emit('close-modal')"
            class="tw-rounded tw-bg-blue-bg200 tw-max-w-[153px] tw-w-full tw-font-CircularStd-medium tw-font-medium tw-text-secondary tw-text-base"
          >
            Skip
          </button>
          <div class="tw-w-full tw-max-w-[153px] ">
            <BtnComponent title="Submit" :loading="loading" />
          </div>
        </div>
      </form>
    </div>
  </Modal>
</template>

<script setup>
import { computed, reactive, ref, watch } from "vue";
import { useStore } from "vuex";
import TextInput from "@/components/general/TextInput";
import SelectInput from "../general/SelectInput.vue";
import TextAreaInput from "@/components/general/TextAreaInput";
import Modal from "@/components/general/Modal";
import BtnComponent from "../general/BtnComponent.vue";

const store = useStore();
const emit = defineEmits(["close-modal", "success"]);
const props = defineProps({
  member: Object,
});
const loading = ref(false);
const currencyList = reactive([
  "🇺🇸 US Dollars (USD)",
  "🇳🇬 Nigerian Naira (NGN)",
  "🇬🇧 Pounds (GBP)",
  "🇪🇺 Euro (EUR)",
  "🇰🇪 Kenyan Shilling (KES)",
  "🇹🇿 Tanzanian Shilling (TZS)",
]);
const accountType = reactive(["savings", "current"]);
const validInput = reactive({
  account_name: false,
  account_number: false,
  bank_name: false,
  sort_code: false,
  bank_address: false,
});
const payload = reactive({
  account_name: "",
  account_number: "",
  currency: "",
  bank_name: "",
  //   code: "",
  sort_code: "",
  swift_code: "",
  bank_address: "",
  //   entity: "user",
  owner: "",
});

const newDealRoomMember = computed(() => {
  return store.state.organisationModule.newDealRoomMember;
});

const addBank = () => {
  setTimeout(async () => {
    if (
      !validInput.account_name ||
      !validInput.account_number ||
      !validInput.bank_name ||
      !validInput.sort_code ||
      !validInput.bank_address
    ) {
      return store.dispatch("showToast", {
        description: "Please fill all input field correctly",
        display: true,
        type: "error",
      });
    }
    loading.value = !loading.value;
    payload.owner = newDealRoomMember.value.email
      ? newDealRoomMember.value.email
      : props.member.email;
    if (payload.currency === "NGN") {
      delete payload.swift_code;
      //   delete this.payload.sort_code;
    }
    const response = await store.dispatch(
      "Account/addMemberBankAccount",
      payload
    );

    const { message } = response.data;
    if (message) {
      store.dispatch("showToast", {
        description: message,
        display: true,
        type: "success",
      });
    }
    if (response) emit("close-modal");
    // this.loading = !this.loading;
    loading.value = !loading.value;
  }, 500);
};

const setAccountName = (text) => {
  payload.account_name = text;
};

const setAccountNumber = (text) => {
  payload.account_number = text;
};

const setCurrency = (option) => {
  if (!option) return;
  let abb = option.split("(")[1].split(")")[0];
  payload.currency = abb;
};

const setBankName = (text) => {
  payload.bank_name = text;
};

const setSortCode = (text) => {
  payload.sort_code = text;
};

const setSwiftCode = (text) => {
  payload.swift_code = text;
};

const setBankAddress = (text) => {
  payload.bank_address = text;
};

const bankAddressValid = (valid) => {
  validInput.bank_address = valid;
};

const bankNameValid = (valid) => {
  validInput.bank_name = valid;
};

const accountNameValid = (valid) => {
  validInput.account_name = valid;
};

const accountNumberValid = (valid) => {
  validInput.account_number = valid;
};

const sortCodeValid = (valid) => {
  validInput.sort_code = valid;
};

const swiftCodeValid = (valid) => {
  validInput.swift_code = valid;
};
</script>

<style lang="scss" scoped></style>
