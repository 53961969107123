<template>
  <table id="members-table" class="tw-w-full tw-mb-8 tw-relative">
    <Loader v-if="loading" />
    <thead class="header">
      <tr class="header-row">
        <th
          class="header-data tw-font-normal tw-py-6"
          ref="tableHead"
          :style="gridStyling"
        >
          <div
            class="tw-justify-self-start tw-pl-10"
            :class="[header === 'id' ? 'tw-hidden' : null]"
            v-for="(header, index) in tableHeaders"
            :key="'header' + index"
          >
            {{ header }}
          </div>
        </th>
      </tr>
    </thead>
    <tbody class="table-body">
      <tr class="body-row">
        <td class="body-data" ref="tableRow">
          <div
            class="data tw-py-6 tw-border-t tw-border-gray-light hover:tw-bg-gray-lightest"
            :ref="rowDetails"
            v-for="(details, index) in tableRows"
            :key="'row' + index"
            :style="gridStyling"
          >
            <div
              class="tw-self-center tw-pl-10"
              :class="[detail.includes('data-id') ? 'tw-hidden' : null]"
              v-for="(detail, i) in details"
              :key="details.id"
            >
              <p
                v-html="detail"
                class="tw-text-sm"
                :class="[i === 'status' ? detail.toLowerCase(0) : null]"
              ></p>
            </div>
            <div class="tw-relative tw-flex tw-justify-center">
              <div
                class="action tw-flex tw-self-center hover:tw-bg-transparent tw-h-4 tw-flex tw-items-center tw-cursor-pointer"
              >
                <img
                  src="https://res.cloudinary.com/djalafcj9/image/upload/v1635311248/getequityV2/options-horizontal_ps9ovq.webp"
                  class="tw-cursor-pointer"
                  alt="share"
                  loading="lazy"
                />
                <div
                  class="menu tw-absolute tw-hidden tw-top-1 tw-right-10 tw-w-56 tw-py-4"
                >
                  <ul
                    class="tw-text-gray tw-text-base tw-p-2 tw-bg-white tw-border tw-rounded"
                  >
                    <li
                      v-if="details.status === 'Active'"
                      class="list tw-flex tw-cursor-pointer hover:tw-text-primary tw-rounded tw-p-1"
                      @click="openAddBankDetailsForm(details)"
                    >
                      <img
                        class="tw-mr-2"
                        src="@/assets/img/bank-info.svg"
                        alt="send deal"
                      />
                      Add bank details
                    </li>
                    <li
                      v-if="details.status === 'Deactivated'"
                      class="list tw-flex tw-cursor-pointer hover:tw-text-primary tw-rounded tw-p-1"
                      @click="setCurrentMember(details.id, 'activate')"
                    >
                      <img
                        class="tw-mr-2"
                        src="@/assets/img/success-circle.svg"
                        alt="activate"
                      />
                      Activate member
                    </li>
                    <li
                      v-if="details.status === 'Active' && deals.length !== 0"
                      class="list tw-flex tw-cursor-pointer hover:tw-text-primary tw-rounded tw-p-1"
                      @click="openSendDealModal(details)"
                    >
                      <img
                        class="tw-mr-2"
                        src="@/assets/img/member-profile.svg"
                        alt="send deal"
                      />
                      Send deal
                    </li>

                    <li
                      v-if="details.status === 'Active'"
                      class="list tw-flex tw-cursor-pointer hover:tw-text-primary tw-rounded tw-p-1"
                      @click="setCurrentMember(details.id, 'deactivate')"
                    >
                      <img
                        class="tw-mr-2"
                        src="@/assets/img/close-circle.svg"
                        alt="decline"
                      />
                      Deactivate member
                    </li>
                    <li
                      v-if="details.status === 'Active'"
                      class="list tw-flex tw-cursor-pointer hover:tw-text-primary tw-rounded tw-p-1"
                      @click="setCurrentMember(details.id, 'remove')"
                    >
                      <img
                        class="tw-mr-2"
                        src="@/assets/img/delete.svg"
                        alt="decline"
                      />
                      Remove member
                    </li>
                    <li
                      v-if="details.status === 'Pending'"
                      class="list tw-flex tw-cursor-pointer hover:tw-text-primary tw-rounded tw-p-1"
                      @click="setCurrentMember(details.id, 'accept')"
                    >
                      <img
                        class="tw-mr-2"
                        src="@/assets/img/success-circle.svg"
                        alt="accept"
                      />
                      Accept
                    </li>
                    <li
                      v-if="details.status === 'Pending'"
                      class="list tw-flex tw-cursor-pointer hover:tw-text-primary tw-rounded tw-p-1"
                      @click="setCurrentMember(details.id, 'decline')"
                    >
                      <img
                        class="tw-mr-2"
                        src="@/assets/img/close-circle.svg"
                        alt="decline"
                      />
                      Decline
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
    <tfoot>
      <Pagination
        class="tw-ml-10"
        :totalPage="totalPage"
        :currentPage="currentPage"
        :maxVisible="9"
        @pageChange="gotoPage"
      />
    </tfoot>
    <AddBankDetails
      v-if="showAddBankDetailsForm"
      :member="member"
      @close-modal="showAddBankDetailsForm = false"
    />
    <SendDeal
      v-if="showTransferTokenModal"
      :show="showTransferTokenModal"
      :member="member"
      :deals="deals"
      @close-send-deal="showTransferTokenModal = false"
    />
    <ActivateModal
      v-if="showActivateModal"
      class="tw-z-20"
      @close="showActivateModal = !showActivateModal"
      :member="currentMember"
      @update-members="$emit('update-members')"
      @close-activate-modal="showActivateModal = false"
    />
    <DeactivateModal
      v-if="showDeActivateModal"
      :member="currentMember"
      @update-members="$emit('update-members')"
      @close-deactivate-modal="showDeActivateModal = false"
    />
    <RemoveModal
      v-if="showRemoveModal"
      :member="currentMember"
      @update-members="$emit('update-members')"
      @close-remove-modal="showRemoveModal = false"
    />
    <AcceptModal
      v-if="showAcceptModal"
      :member="currentMember"
      @update-members="$emit('update-members')"
      @close-accept-modal="showAcceptModal = false"
    />
    <DeclineModal
      v-if="showDeclineModal"
      :member="currentMember"
      @update-members="$emit('update-members')"
      @close-decline-modal="showDeclineModal = false"
    />
  </table>
</template>

<script>
  import { mapActions } from "vuex";
  import DeactivateModal from "@/components/members/DeactivateModal";
  import AddBankDetails from "@/components/members/AddBankDetails";
  import ActivateModal from "@/components/members/ActivateModal";
  import RemoveModal from "@/components/members/RemoveModal.vue";
  import AcceptModal from "@/components/members/AcceptModal";
  import DeclineModal from "@/components/members/DeclineModal";
  import TransferTokenModal from "@/components/general/MediumModal";
  import Pagination from "@/components/navigation/Pagination";
  import SendDeal from "@/components/members/SendDeal";

  export default {
    name: "MembersTable",

    components: {
      Pagination,
      DeactivateModal,
      ActivateModal,
      AcceptModal,
      DeclineModal,
      AddBankDetails,
      TransferTokenModal,
      RemoveModal,
      SendDeal,
    },

    props: {
      tableHeaders: { type: Array, default: () => [], required: true },
      tableRows: { type: Array, default: () => [], required: true },
      columnNumber: { type: Number, default: () => 0, required: true },
      columnWidth: { type: Number, default: () => 0, required: true },
      totalPage: { type: Number, default: () => 0, required: true },
      currentPage: { type: Number, default: () => 0, required: true },
      deals: { type: Array, default: () => [], required: true },
    },

    emits: ["gotoPage", "update-members"],

    data() {
      return {
        rows: [],
        type: "",
        columnSize: "",
        id: "",
        member: {},
        currentMember: null,
        loading: false,
        dealsLoading: false,
        showActivateModal: false,
        showDeActivateModal: false,
        showRemoveModal: false,
        showAcceptModal: false,
        showDeclineModal: false,
        showTransferTokenModal: false,
        showAddBankDetailsForm: false,
        statuses: [""],
      };
    },
    computed: {
      gridStyling() {
        return {
          "grid-template-columns": `${200}px ${200}px ${200}px ${200}px ${120}px repeat(${
            this.columnNumber
          }, ${0}px)`,
        };
      },
    },

    methods: {
      ...mapActions("organisationModule", ["pendingInvite"]),

      rowDetails(el) {
        this.rows.push(el);
      },

      async openSendDealModal(member) {
        this.showTransferTokenModal = true;
        this.dealsLoading = true;
        this.member = member;
        return;
      },
      async openAddBankDetailsForm(member) {
        this.showAddBankDetailsForm = true;
        this.member = member;
        return;
      },
      setCurrentMember(memberId, modalType) {
        this.currentMember = memberId.split("/")[1];
        switch (modalType) {
          case "deactivate":
            this.showDeActivateModal = true;
            break;
          case "activate":
            this.showActivateModal = true;
            break;
          case "decline":
            this.showDeclineModal = true;
            break;
          case "accept":
            this.showAcceptModal = true;
            break;
          case "remove":
            this.showRemoveModal = true;
            break;
        }
      },

      async acceptMember(event, memberId) {
        if (event.target.innerText === "Accept") {
          this.id = memberId.split("/")[1];
          this.showAcceptModal = true;
          return;
        }
        this.acceptLoading = true;
        const payload = { id: this.id, action: "accept" };
        const response = await this.pendingInvite(payload);
        if (response) {
          this.$emit("update-members");
        }
        this.acceptLoading = false;
      },

      setTableColumn() {
        this.columnSize = `repeat(${this.columnNumber}, ${
          this.columnWidth
        }px) ${120}px`;
        this.setColumnStatus();

        this.$refs.tableHead.style.gridTemplateColumns = this.columnSize;
        this.rows.forEach((row) => {
          row.style.gridTemplateColumns = this.columnSize;
        });
      },

      setColumnStatus() {
        this.rows.forEach((row) => {
          let columns = row?.querySelectorAll("p");
          if (columns[columns.length - 1].innerHTML === "Active") {
            columns[columns.length - 1].classList.add("active");
          } else if (columns[columns.length - 1].innerHTML === "Deactivated") {
            columns[columns.length - 1].classList.add("deactivated");
          } else {
            columns[columns.length - 1].classList.add("pending");
          }
        });
      },

      gotoPage(page) {
        this.$emit("gotoPage", page);
      },
    },
  };
</script>

<style lang="scss" scoped>
  #members-table {
    @include fadeIn;

    .header {
      .header-row {
        .header-data {
          display: grid;
          column-gap: 2rem;
        }
      }
    }

    .table-body {
      .body-row {
        .body-data {
          .data {
            display: grid;
            column-gap: 2rem;
            font-size: 0.7rem;

            .action {
              .syndicate-name {
                @media screen and (min-width: 768px) {
                  min-width: 5rem;
                }
              }

              .menu {
                z-index: 1;
                .list {
                  transition: 0.3s ease all;
                }
              }

              &:hover .menu {
                display: block;
              }
            }
          }

          // Added with javascript
          .active,
          .pending,
          .deactivated {
            text-align: left;
            padding: 0.5rem;
            border-radius: 5px;
          }

          .active {
            color: $success;
            background-color: $success-light;
            max-width: 3.5rem;
          }
          .pending {
            color: $warning;
            background-color: $warning-light;
            max-width: 4.2rem;
          }

          .deactivated {
            color: $error;
            background-color: $error-light;
            max-width: 5.7rem;
          }
        }
      }
    }

    .loading {
      @media screen and (min-width: 1280px) {
        margin-right: 20%;
      }
    }
  }
</style>
