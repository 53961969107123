<template>
	<Modal :show="true" @close-modal="$emit('close-modal')">
		<div class="tw-w-full md:tw-w-[480px] lg:tw-w-[800px]">
			<h1
				class="tw-font-bold tw-text-[22px] tw-text-secondary tw-mb-2 tw-text-left"
			>
				Add multiple members at once.
			</h1>
			<p class="tw-text-gray tw-mb-4">
				To add multiple members at once, you need to follow the following
				instructions.
			</p>
			<ul class="tw-list-decimal list--margin">
				<li class="tw-mb-4">
					Download the
					<a
						class="tw-text-primary tw-underline"
						href="https://res.cloudinary.com/djalafcj9/raw/upload/v1686140334/getequityV2/members_sample_csv_xmhh4d.csv"
						target="_blank"
						rel="noopener noreferrer"
						>sample CSV file</a
					>.
				</li>
				<li class="tw-mb-4">
					Fill it with the appropriate information for each member.
				</li>
				<li class="tw-mb-4">
					<p class="tw-mb-2">Upload the newly completed CSV file</p>
					<DragNDrop
						accept=".csv"
						:process-file="true"
						:currentValue="csvFile"
						@file-added="setDocument"
						@re-upload="csvFile = ''"
						@remove-file="csvFile = ''"
						:start-upload="startUpload"
						@upload-complete="setDocument($event, true)"
					/>
				</li>
				<li class="tw-mb-4">
					<p class="tw-mb-2">Add Members</p>
					<div class="tw-w-full tw-max-w-[200px]">
						<CustomButton
							@click="batchRequest"
							title="Submit"
							:disabled="members.length === 0"
							:loading="loading"
						/>
					</div>
				</li>
			</ul>
		</div>
	</Modal>
</template>

<script setup>
import CustomButton from "@/components/general/BtnComponent.vue";
import Modal from "@/components/general/Modal";
import { computed, reactive, ref, watch } from "vue";
import { useStore } from "vuex";
import DragNDrop from "../general/DragNDrop.vue";

const emit = defineEmits(["close-modal", "success"]);
const store = useStore();

const members = reactive([]);
const csvFile = ref("");
const startUpload = ref(false);
const batchSize = ref(10);

const loading = computed(() => store.getters["loading"]);

const orgDetails = computed(
	() => store.getters["organisationModule/organisationDetails"]
);

const setDocument = (value, bool) => {
	csvFile.value = value;
	if (bool) {
		addMembers();
	}
};
const processData = () => {
	const lines = csvFile.value.split("\n");

	// Iterate over each line of the CSV (excluding 0 which is the title)
	for (let i = 1; i < lines.length; i++) {
		const line = lines[i].trim();

		// Skip empty lines
		if (line === "") {
			continue;
		}

		const values = line.split(",");
		// create member object
		const member = {
			fname: null,
			lname: null,
			phone: null,
			email: null,
			kyc: true,
			password: "1@Getequity",
		};
		// assign each value to the appropriate param
		values.map((value, index) => {
			member[Object.keys(member)[index]] = value;
		});
		// Add the member object to the members array
		members.push(member);
	}
};

const batchRequest = async () => {
	if (members.length > batchSize.value) {
		await processBatch(members);
	} else {
		try {
			await addMembers(members);
			emit("success");
		} catch (error) {
			console.error("Error adding members:", error);
		}
	}
};

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const processBatch = async (remainingMembers) => {
	if (remainingMembers.length > 0) {
		const batch = remainingMembers.slice(0, batchSize.value);
		try {
			await addMembers(batch);
			const newRemainingMembers = remainingMembers.slice(batchSize.value);
			// Wait for 1 minute before processing the next batch
			await sleep(1500); // 1sec
			await processBatch(newRemainingMembers);
		} catch (error) {
			console.error("Error adding members:", error);
		}
	} else {
		emit("success"); // Emit success only after all batches are processed
	}
};

const addMembers = async (membersArray) => {
	try {
		await Promise.all(
			membersArray.map((member) =>
				store.dispatch("organisationModule/addMemberToDealRoom", {
					payload: member,
					id: orgDetails.value._id,
				})
			)
		);
	} catch (error) {
		console.error("Error adding members:", error);
		throw error; // Propagate error to the caller
	}
};

watch(csvFile, () => {
	members.value = [];
	processData();
});
</script>

<style lang="scss" scoped>
.list {
	&--margin {
		margin-inline-start: 30px;
	}
}
</style>
