<template>
  <section
    class="search tw-flex tw-justify-end tw-items-center tw-h-14 tw-mr-2"
    :class="[
      showSearchField
        ? useFullWidth
          ? 'search--full-width'
          : 'search--expanded'
        : 'tw-w-6 search--minimized',
    ]"
  >
    <transition name="fade-transform" mode="out-in">
      <form
        @submit.prevent=""
        v-if="showSearchField"
        class="tw-mr-2 search__form"
      >
        <input
          type="search"
          name="search"
          :placeholder="placeholder"
          id="search"
          v-model="searchValue"
          ref="searchInput"
          v-focus
          @keyup.enter="
            $emit('search-enter', {
              value: searchValue.toLowerCase(),
              type: type,
            })
          "
          @input="
            $emit('search-input', {
              value: searchValue.toLowerCase(),
              type: type,
            })
          "
          class="tw-h-10 tw-pl-5 search__input tw-w-full tw-rounded tw-text-secondary tw-bg-white"
          @blur="$emit('search-click')"
        />
      </form>
      <button
        class="tw-w-5 tw-h-5 search__button"
        @click="$emit('search-click')"
        v-else
      >
        <img
          src="https://res.cloudinary.com/djalafcj9/image/upload/v1635478252/getequityV2/search-icon_h8kf6j.webp"
          class="tw-w-5 tw-h-5"
          alt="search"
          loading="lazy"
        />
      </button>
    </transition>
  </section>
</template>

<script>
  export default {
    name: "Search",
    data() {
      return {
        searchValue: "",
      };
    },
    emits: ["search-click", "search-input", "search-enter"],
    props: {
      showSearchField: {
        type: Boolean,
        required: true,
      },
      placeholder: {
        type: String,
        default: "Search",
      },
      type: {
        type: String,
        required: false,
        default: "name",
      },
      useFullWidth: {
        type: Boolean,
        required: false,
        default: false,
      },
      clearValue: {
        type: Boolean,
        required: false,
        default: () => false,
      },
    },
    directives: {
      focus: {
        mounted(el) {
          el.focus();
        },
      },
    },
    watch: {
      clearValue(val) {
        if (val) {
          this.searchValue = "";
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .search {
    transition: width 300ms ease-in-out;
    &--expanded {
      width: calc(100% - 41px);
      max-width: 290px;
      @media (min-width: 768px) {
        max-width: 220px;
      }
    }
    &--full-width {
      max-width: 100%;
      margin-right: 0;
      .search__form {
        margin-right: 0;
      }
    }
    &--minimized {
      max-width: 24px;
    }
    &__form {
      flex-basis: 100%;
      display: block;
    }
    &__button {
      &:hover {
        background: transparent;
        cursor: pointer;
      }
    }
    &__input {
      border: 1px solid #dde1e9;
    }
  }
</style>
