<template>
  <SmallModal class="tw-z-20" @close="$emit('close-deactivate-modal')">
    <template v-slot:content>
      <div class="header">
        <h3 class="tw-text-xl tw-font-CircularStd-medium tw-font-medium">
          Deactivate member?
        </h3>
        <p class="tw-text-black-light tw-mt-6">
          This will remove this user from deals and deactivate their account.
        </p>
      </div>
      <button
        class="tw-w-full tw-bg-error tw-text-white tw-rounded tw-py-3 tw-my-8"
        @click="deActivateAMember"
      >
        <span v-if="!loading">Yes, Deactivate</span>
        <img
          v-show="loading"
          class="tw-m-auto"
          src="@/assets/img/spinner.svg"
          alt="animated spinner"
        />
      </button>
      <p class="tw-text-center">
        <span
          class="tw-text-gray tw-cursor-pointer"
          @click="$emit('close-deactivate-modal')"
        >
          No, never mind
        </span>
      </p>
    </template>
  </SmallModal>
</template>

<script>
  import SmallModal from "@/components/general/SmallModal";
  import { mapActions } from "vuex";
  export default {
    name: "DeactivateModal",
    data() {
      return {
        loading: false,
      };
    },
    emits: ["close-deactivate-modal", "update-members"],
    components: {
      SmallModal,
    },
    props: {
      member: {
        type: String,
        required: true,
      },
    },
    methods: {
      ...mapActions("organisationModule", ["deActivateMember"]),
      async deActivateAMember() {
        const id = this.member;
        this.loading = true;
        const response = await this.deActivateMember(id);
        this.loading = false;
        if (response) {
          this.$emit("update-members");
        }
      },
    },
  };
</script>
